
<div id="body">
 
  <div id="header" class="clearfix container-fluid">    
    <div class="navbar">

      <a href="#" class="logo">
        <img src="assets/images/shared/gothic symbol filler.jpg" alt="Kaercha Studios">
      </a>    
  </div>

  <div class="studio">   
    <featured-item *ngFor ="let game of games" [game]="game" (click)="gameclicked($event)"></featured-item>
  </div>

   </div>
</div>a