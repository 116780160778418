import { Component } from '@angular/core';
import { gamebanner } from './entities/gamebanner';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'penumbra-walker-web';

  constructor()
  {
    var g = new gamebanner();
    g.caption = `Kaercha Studio\'s new Game, Penumbra Walker is coming soon
    This will be a description of the game once we actually get where we need to be`;
    g.heading = 'Penumbra Walker';
    g.id = 1;
    g.imageurl = 'assets/images/shared/gothic filler.jpg';
    this.games.push(g);

    g = new gamebanner();
    g.caption = `Kaerrcha Stuido\'s second upcoming game will knock your socks off! 
    Join Remiel as he quests to regain his divinity.`;
    g.heading = 'Penumbra Tactics';
    g.id = 2;
    g.imageurl = 'assets/images/shared/gothic filler 2.jpg';
    this.games.push(g);

  }

  games = []


  gameclicked(game:gamebanner)
  {
    console.log("Game clicked",game);
  }


}
