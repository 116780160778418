<div class="featured with-text">   
    <div class="featured-image" style="background-image:url('{{game.imageurl}}')">     
    <div class="container">      
    <div class="caption caption-verticle-centered">
      <h3>{{game.heading}}</h3>
      <p>{{game.caption}}</p>      
      <a class="btn btn-success" (click)="clicked()">Learn More</a>      
    </div>
  </div>
  </div>
</div>