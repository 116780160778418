import { Component, EventEmitter, Output } from '@angular/core';
import {Input } from '@angular/core';
import { pathToFileURL } from 'url';
import { gamebanner } from '../entities/gamebanner';

@Component({
  selector: 'featured-item',
  templateUrl: './featured-item-component.html',
  styleUrls: ['./featured-item-component.css']
})

export class FeaturedItemComponent {

    @Input() 
    game : gamebanner;

    @Output()
    click = new EventEmitter<gamebanner>();

    clicked() {
        this.click.emit(this.game);
    }

  }
